<template>
  <div>
    <div >
      <div v-if="l_state==1" class="confirmAssess-container assess-cloor">
        <div class="confirmAssess-header">
          <img src="../../../images/znzy.png" alt=" ">
        </div>
        <div class="confirmAssess-txt">
          <div class="confirm-title flex"><img src="../../../images/qy.png"/><div>权益介绍</div></div>
          <div class="confirm-body">“AI智能中医”权益是指用户自己通过手机摄像头采集自己的面象、舌象信息，结合问诊，经过AI智能中医大脑的分析评估后，即可获取自己当前的中医健康状态，同时AI智能中医还会根据每个用户的不同健康情况，智能生成个性化的养生保健方案，包括茶饮养生、药膳配方、足浴养生、艾灸养生、音乐养生等方案。</div>
          <div class="confirm-body">整个流程可在手机端完成，高效便捷，便于用户随时随地了解和调理自己的健康。使用过程非常简单，按照系统提示：</div>
          <div class="confirm-body">第一步，拍摄面部照片；</div>
          <div class="confirm-body">第二步，拍摄舌部照片；</div>
          <div class="confirm-body">第三步，回答问诊选择题；</div>
          <div class="confirm-body">第四步，然后“提交“，即可获取自己的智能中医健康报告。</div>
          <div class="confirm-title flex"><img src="../../../images/sq.png"/><div>申请方式</div></div>
          <div class="confirm-body">申请流程：进入【西湖益联保】微信公众号-＞【服务中心】-＞【增值服务】-＞【AI智能中医】-＞【导入个人权益码】-＞【权益介绍】-＞【兑换权益】-＞【使用权益】</div>
          <div class="confirm-title flex"><img src="../../../images/sx.png"/><div>服务时效</div></div>
          <div class="confirm-body">2024.1.1-2024.12.31 全天可用，建议在7:00-17:00之间，自然光线下使用。</div>
          <div class="confirm-title flex"><img src="../../../images/fw.png"/><div>服务次数</div></div>
          <div class="confirm-body">服务时效内不限使用次数。</div>
          <div class="confirm-title flex"><img src="../../../images/gz.png"/><div>服务规则</div></div>
          <div class="confirm-body">1. “AI智能中医”出具的健康报告仅限用于养生保健指导参考，不能作为诊疗或购药依据或代替线下医疗机构医生为您开具的诊断意见或医嘱。您如需疾病诊断或用药治疗，请至正规医院进行问诊咨询。</div>
          <div class="confirm-body">2. 为保证“AI智能中医”分析的准确性，请不要在昏暗的光线下或有颜色的光线下进行分析评估。拍面象舌象照片时请关掉美颜。</div>
          <div class="confirm-body">3. 拍舌象是请将舌头尽量自然平整地伸出，舌头伸出太少可能识别不通过。</div>
        </div>
      </div>
      <div v-else class="confirmAssess-container">
        <div class="confirmAssess-header">
          <img src="../../../images/sys.png" alt=" ">
        </div>
        <div class="confirmAssess-content">
          <div
            v-for="(item, index) in content"
            :key="index"
            class="content-item"
          >
            <img :src="item.img" alt=" "/>
            <div class="title">{{ item.title }}</div>
            <div class="content">{{ item.subtitle }}</div>
          </div>
        </div>
        <div class="confirmAssess-step" v-if="l_state==3||l_state==2||l_state==4">
          <p>健康评估流程</p>
          <div class="step-container">
            <img :src="item" alt=" " v-for="(item, index) in stepsImage" :key="index"/>
          </div>
        </div>
        <div class="confirmAssess-bottom">
          <p class="tips">点击“开始评估”，即表示您同意将照片上传至系统后台,用于健康分析</p>
          <div v-if="l_state==2">
            <van-button type="primary" class="button-type" @click="confirmAssessFun" v-if="go">开始评估</van-button>
            <van-button type="primary" class="button-thin" v-else>开始评估</van-button>
          </div>
        </div>
      </div>
      <div class="confirmAssess-code flex" :class="{ assess: l_state==1 }" v-if="l_state==1||l_state==3">
          <div>请输入权益码：</div>
          <div class="flex-1">
              <input type="text" v-model="code" />
          </div>
          <div class="confirmAssess-submit" @click="submit">验证</div>
      </div>
    </div>
  </div>
</template>
<script>
  // import vueHeader from '@/components/header' 
  import { mapActions , mapState , mapMutations } from 'vuex';
  // import { getDomain } from '@/utils';
  export default {
    name: "confirmAssess",
    created:function(){

        let flow = JSON.parse(sessionStorage.getItem('flow'));
        //参数
        let assess = {
          server_type:3,
          user_id:0,
          yao_account_id:0,
          sys_company_id:0
        }

        if(this.$route.query.user_id){
            assess.user_id = this.$route.query.user_id;
        }
        if(this.$route.query.returnUrl){
            sessionStorage.setItem('returnUrl',this.$route.query.returnUrl);
        }
        if(this.$route.query.yao_account_id){
            assess.yao_account_id = this.$route.query.yao_account_id;
        }
        if(this.$route.query.sys_company_id){
            assess.sys_company_id = this.$route.query.sys_company_id;
        }
        if(this.$route.params.id){
          assess.server_type = this.$route.params.id;
        }

        //西联保
        let token = localStorage.token;
        //let card = localStorage.card;
        if(token){          //如果有token,第二次进来
          sessionStorage.setItem("token",token);
          this.userData();  //用户信息（权益码）
          // let device = sessionStorage.getItem('device');
          // let domain = getDomain();
          // if((flow && (device=='android' || device=='windows'))||domain=='localhost'){
          //     this.change({
          //       key:'go',
          //       value:true
          //     })
          // }else{
          //   if(this.$route.params.id){
          //     this.axiosData({
          //       ...assess,
          //       server_type:this.$route.params.id
          //     })
          //   }
          // }
        }

        // if(!card && !token){  //第一次进来
        //     this.change2({
        //       key:'l_state',
        //       value:1
        //     })
        // }

        let returns = this.$route.query.return;        
        if(returns){
            sessionStorage.setItem('return',returns);
        }
        
        //如果有手机号
        let phone = this.$route.query.phone;
        if(phone){
            sessionStorage.setItem("phone",phone);
            this.axiosTel({
                data:{
                    company_id:assess.sys_company_id,
                    phone:phone,
                    name:'',
                    birthday:''
                },
                _this:this,
                func:(data)=>{
                    assess.user_id = data.user_id;
                    sessionStorage.setItem('assess',JSON.stringify(assess));
                }
            })
        }else{
            sessionStorage.setItem('assess',JSON.stringify(assess));
        }
        
        this.checkData();

        let device = sessionStorage.getItem('device');
        //let domain = getDomain();
        if((flow && (device=='android' || device=='windows'))){
            this.change({
              key:'go',
              value:true
            })
        }else{
          if(this.$route.params.id){
            this.axiosData({
              ...assess,
              server_type:this.$route.params.id
            })
          }
        }
    },
    data () {
      return {
        content: [
          {
            img: require("../../../images/pick.png"),
            title: "专业",
            subtitle: "国家科技部和中医药管理局重大研发项目“中医四诊现代化”系列成果转化",
          },
          {
            img: require("../../../images/nao.png"),
            title: "智能",
            subtitle: "浙江大学人工智能研究所合作开发",
          },
          {
            img: require("../../../images/safe.png"),
            title: "安全",
            subtitle: "阿里云部署",
          },
        ],
        code:''
      };
    },
    computed:{
      ...mapState('confirmAssess',['go','stepsImage']),
      ...mapState('xlb',['l_state']),
    },
    methods:{
      confirmAssessFun(){
        // this.axiosData({})
        let flow = sessionStorage.getItem('flow');
        let flow_step;
        if(flow){
          flow_step = JSON.parse(flow).flow_info.flow_step;
        }

        if(flow_step==4){
          this.$router.push({
              name:'confirmTongue'
          });
        }else{
          this.$router.push({
              name:'confirmToface'
          });
        }
      },
      submit(){
          if(this.l_state==1){
            this.xlbData({
                card:this.code
            })
          }else if(this.l_state==3){
            this.bindData({
                card:this.code
            })
          }
      },
      ...mapMutations ("confirmAssess", ["change"]),
      ...mapMutations ("xlb", ["change2"]),
      ...mapActions('confirmAssess',['axiosData','checkData']),
      ...mapActions('device',['axiosTel']),
      ...mapActions('xlb',['xlbData','userData','bindData'])
    }
  };
</script>
<style lang="scss" scoped rel="stylesheet/scss">
  @import 'confirmAssess';
</style>
